<template>
  <v-app>
    <v-navigation-drawer
      class="my-navigation-drawer"
      :class="{ 'display-bg-img': displayBgImg }"
      mini-variant-width="72"
      :expand-on-hover="sideBarSettings.mini"
      :permanent="true"
      :color="sidebarBg"
      dark
      app
    >
      <template #prepend>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="logoSrc"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>笔记后台</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-group class="avatar-group" color="#fff" :value="false" no-action>
            <template #activator>
              <v-list-item-avatar>
                <v-img :src="logoSrc"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>管理员</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item @click="onlogout">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>退出登录</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>

      <v-divider></v-divider>

      <v-list expand shaped>
        <template v-for="(item) in secondLevelMenus">
          <v-list-group
            v-if="item.children.length"
            :key="item.menuKey"
            color="#fff"
            v-model="item.active"
            :prepend-icon="'mdi-' + item.menuIcon"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.menuName"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="item in item.children"
              :key="item.menuKey"
              :to="item.frontKey"
              :exact-active-class="sidebarColor"
              link
            >
              <v-list-item-action>
                <p class="text-uppercase">{{ item.menuIcon }}</p>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.menuName"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="item.menuKey"
            :to="item.frontKey"
            :exact-active-class="sidebarColor"
            link
          >
            <v-list-item-action>
              <v-icon>mdi-{{ item.menuIcon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.menuName" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      elevate-on-scroll
      :color="sidebarBg === 'black' ? 'grey darken-3' : 'red lighten-1'"
      class="top-drawer"
      src="https://picsum.photos/1920/1080?random"
      scroll-target="#scroll"
      height="72"
      dark
    >
      <v-app-bar-nav-icon @click.stop="sideBarSettings.mini = !sideBarSettings.mini"></v-app-bar-nav-icon>
      <v-toolbar-title>笔记服务平台</v-toolbar-title>
      <v-spacer />
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-menu transition="slide-x-reverse-transition" offset-y :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn v-on="on" fab icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <side-bar-set v-model="sideBarSettings"></side-bar-set>
      </v-menu>
      <template #extension v-if="openTab.length > 0">
        <v-tabs
          style="border-top: 1px solid rgba(255, 255, 255, 0.12);"
          center-active
          dark
          v-model="currentTab"
          id="scroll"
          :active-class="sidebarColor"
        >
          <v-tab
            v-for="(item, index) in openTab"
            :key="item.name"
            @click="goPage(item,index)"
            active-class="blue"
          >
            <div class="btn-item d-flex align-center">
              <span>{{ item.title }}</span>
              <v-icon
                color="#fff"
                transition="fade-transition"
                size="13"
                dark
                @click.stop="delRouterTab(index)"
              >mdi-close</v-icon>
            </div>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main class="mt-3">
      <v-container fluid>
        <SlideXLeftTransition mode="out-in">
          <keep-alive :include="tabItems">
            <router-view></router-view>
          </keep-alive>
        </SlideXLeftTransition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  components: { SlideXLeftTransition },
  data() {
    return {
      logoSrc: require('@/assets/logo.png'),
      secondLevelMenus: [
        {
          menuKey: '1',
          frontKey: '/notesList',
          menuIcon: 'playlist-edit',
          menuName: '笔记列表',
          children: []
        },
        {
          menuKey: '2',
          frontKey: '/userList',
          menuIcon: 'account-arrow-right',
          menuName: '用户列表',
          children: []
        },
        {
          menuKey: '3',
          frontKey: '/urlList',
          menuIcon: 'earth',
          menuName: '推荐网址列表',
          children: []
        },
        {
          menuKey: '4',
          frontKey: '/createNews',
          menuIcon: 'newspaper-plus',
          menuName: '发布消息',
          children: []
        }
      ],
      currentTab: null,
      openTab: [],
      sideBarSettings: {
        sidebarColors: [
          { color: 'purple', active: false },
          { color: 'green', active: false },
          { color: 'blue', active: true },
          { color: 'orange', active: false },
          { color: 'pink', active: false }
        ],
        sidebarBg: [
          { color: 'black', active: true },
          { color: 'red', active: false }
        ],
        mini: true,
        displayBgImg: true,
        sidebarImages: [{ src: require('@/assets/logo.png'), active: true }]
      }
    }
  },
  computed: {
    tabItems() {
      return this.openTab.map((v) => v.name)
    },
    sidebarColor() {
      const find = this.sideBarSettings.sidebarColors.find((ele) => ele.active)
      return find.color
    },
    sidebarBg() {
      const find = this.sideBarSettings.sidebarBg.find((ele) => ele.active)
      return find.color
    },
    displayBgImg() {
      return this.sideBarSettings.displayBgImg
    },
    siderbarSrc() {
      const find = this.sideBarSettings.sidebarImages.find((ele) => ele.active)
      return find.src
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        if (route.meta.isCache) {
          const find = this.openTab.find((v) => v.name === route.name)
          if (find) return this.setCurrentTab(find)

          const r = {
            title: route.meta.title,
            name: route.name,
            query: route.query
          }
          this.addRouterTab(r)
          this.setCurrentTab(r)
        }
      }
    }
  },
  methods: {
    onlogout() {
      this.$cux.asyncOperation({
        icon: 'warning',
        text: '确定退出登录吗?',
        preConfirm: async () => {
          this.$util.delStorage('session', 'token')
          this.$router.push('/login')
        }
      })
    },
    setCurrentTab(route) {
      const index = this.openTab.indexOf(route)
      this.currentTab = index
    },
    goPage(item) {
      this.$router.push({
        name: item.name,
        query: item.query
      })
    },
    delRouterTab(index) {
      if (this.currentTab === index) {
        this.openTab.splice(index, 1)
        const route = this.openTab[this.openTab.length - 1]
        if (route) {
          this.goPage({
            name: route.name,
            query: route.query
          })
        }
      } else {
        const current = this.openTab[this.currentTab]
        this.openTab.splice(index, 1)
        this.setCurrentTab(current)
      }
    },
    addRouterTab(item) {
      this.openTab.push(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar.v-app-bar--is-scrolled {
  background-color: rgba(255, 255, 255, 0.95) !important;
}
.app-bar-market {
  flex: 0 0 130px;
}
.btn-item .v-icon {
  margin-left: 5px;
}
.router-btns {
  border-radius: 4px 4px 0 0 !important;
  margin-right: 4px;
}
.v-toolbar__extension {
  padding: 0 !important;
}
.text-uppercase {
  margin-bottom: 0px;
}
</style>
